<template>
  <div class="main-container">
    <el-card
      :body-style="{padding: '10px'}"
      shadow="hover">
      <!-- <div
        class="text-bold text-xl">
        首页</div> -->
      <div
        v-if="!isMobileScreen"
        class="flex margin-top">
        <div
          class="avatar-wrapper">
          <img
            :src="avatar" />
        </div>
        <div
          class="flex-sub flex flex-direction justify-around margin-left">
          <div
            class="text-xl">
            {{ userInfo.name }}，青春只有一次，别让自己过得不精彩
          </div>
          <!-- <div
            class="text-grey text-sm">
            <i
              class="el-icon-heavy-rain"></i>
            今日有小雨，出门别忘记带伞哦~
          </div> -->
        </div>
      </div>
      <div v-else>
        <div class="flex">
          <div
            class="avatar-wrapper margin-top">
            <img
              :src="avatar" />
          </div>
          <div
            class="flex-sub flex flex-direction justify-around margin-left">
            <div
              class="text-xl">
              {{ userInfo.name }}，青春只有一次，别让自己过得不精彩
            </div>
            <!-- <div
              class="text-grey text-sm">
              <i
                class="el-icon-heavy-rain"></i>
              今日有小雨，出门别忘记带伞哦~
            </div> -->
          </div>
        </div>
      </div>
    </el-card>
    <listView />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import listView from './components/list.vue'
export default {
  name: 'WorkPlace',
  components: { listView },
  data() {
    return {
      userInfo: {},
      currentDate: ''
    }
  },
  computed: {
    ...mapGetters({
      avatar: 'user/getAvatar'
    }),
    isMobileScreen() {
      return this.$layoutStore.state.device === 'mobile'
    },
    isShowMore() {
      return this.waitingItmes.length > 4
    }
  },
  mounted() {
    this.currentDate = moment().format('YYYY-MM-DD')
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.avatar-wrapper {
  width: 5rem;
  height: 5rem;
  & > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid yellowgreen;
  }
}
.item-action {
  position: relative;
  padding: 0 30px;
}
.item-action::after {
  position: absolute;
  top: 20%;
  right: 0;
  height: 60%;
  content: '';
  display: block;
  width: 1px;
  background-color: #e0e0e0;
}
div.item-action:last-child::after {
  width: 0;
}
.el-link + .el-link {
  margin-bottom: 10px;
}
</style>
