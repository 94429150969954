<!--
 * @Description:: 育小帮科技有限公司
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2022-11-04 16:43:33
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-29 13:22:58
 * @FilePath: /siana_admin/src/views/index/components/list.vue
-->
<template>
  <div>
    <Header :obj="obj"
      :phone="phone" />
    <el-row :gutter="20">
      <el-col :lg="24"
        :md="24" :sm="24">
        <el-card
          style="margin-top: 20px;">
          <div slot="header">
            <div class="left">
              订单数</div>
          </div>
          <div ref="main_a"
            style="width:100%;height:400px">
          </div>
        </el-card>
      </el-col>
      <el-col :lg="24"
        :md="24" :sm="24">
        <el-card
          style="margin-top: 20px;">
          <div slot="header">
            <div class="left">
              金额</div>
          </div>
          <div ref="main_b"
            style="width:100%;height:400px">
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import echarts from 'echarts'
import moment from 'moment'
// 引入组件及方法
import Header from './header.vue'
// 引入组件
export default {
  name: 'Yxb30uiIndex',
  components: { Header },
  // 数据
  data() {
    return {
      phone: false,
      obj: {
        expirationNumber: 0,
        dayPrice: 0,
        dayOkOrderNumber: 0,
        dayOrderNumber: 0,
        dayUserNumber: 0
      },
      datas: [],
      dataY: [],
      dataX: [],
      priceList: [],
      dataOrders: []
    }
  },
  //  初始化
  mounted() {
    this.phone = JSON.parse(window.localStorage.getItem('phone'))

    this.getDate()
  },

  methods: {
    moment,
    getDate() {
      this.dataX = []
      this.datas = []
      const year = moment().year()
      const month = moment().month() + 1 < 10 ? `0${moment().month() + 1}` : moment().month() + 1
      const start = parseInt(moment().startOf('month').format('DD'))
      const end = parseInt(moment().endOf('month').format('DD'))
      for (var i = start; i <= end; i++) {
        const ii = i < 10 ? `0${i}` : i
        const obj = {
          orderNumber: 0,
          price: 0,
          date: year + '-' + month + '-' + ii
        }
        this.dataX.push(`${year + '-' + month + '-' + ii}`)
        this.datas.push(obj)
      }
      this.getData()
    },
    getData() {
      this.dataOrders = []
      this.priceList = []

      this.$https('HOME', {}).then(res => {
        const orderList = res.data.orderList
        this.obj = {
          expirationNumber: res.data.expirationNumber,
          dayPrice: res.data.dayPrice,
          dayOkOrderNumber: res.data.dayOkOrderNumber,
          dayOrderNumber: res.data.dayOrderNumber,
          dayUserNumber: res.data.dayUserNumber
        }
        if (orderList && orderList.length > 0) {
          for (let i = 0; i < orderList.length; i++) {
            for (let y = 0; y < this.datas.length; y++) {
              if (this.datas[y].date == orderList[i].days) {
                this.datas[y].orderNumber = orderList[i].orderNumber
              }
            }
          }
          this.dataOrders = this.datas.map(item => {
            return item.orderNumber
          })
          this.getEchartData()
        }
        const orderPriceList = res.data.orderPriceList
        if (orderPriceList && orderPriceList.length > 0) {
          for (let i = 0; i < orderPriceList.length; i++) {
            for (let y = 0; y < this.datas.length; y++) {
              if (this.datas[y].date == orderPriceList[i].days) {
                this.datas[y].price = orderPriceList[i].price
              }
            }
          }
          this.priceList = this.datas.map(item => {
            return item.price
          })
          this.getEchartDatas()
        }
      })
    },

    getEchartData() {
      const chart = this.$refs.main_a
      if (chart) {
        const myChart = echarts.init(chart)
        const option = {
          color: ['rgb(134, 94, 192)'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: this.dataX,
              axisLabel: {
                rotate: 40
              },
              axisTick: {
                alignWithLabel: true
              }
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '订单数量',
              type: 'bar',
              // barWidth: '60%',
              data: this.dataOrders
            }
          ]
        }

        myChart.setOption(option)
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      }
      this.$on('hook:destroyed', () => {
        window.removeEventListener('resize', function () {
          myChart.resize()
        })
      })
    },
    getEchartDatas() {
      const chart = this.$refs.main_b
      if (chart) {
        const myChart = echarts.init(chart)
        const option = {
          color: ['rgb(134, 94, 192)'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },

          xAxis: {
            type: 'category',
            data: this.dataX,
            axisLabel: {
              rotate: 40
            },
            scale: true,
            axisTick: {
              alignWithLabel: true
            }
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: '金额',
              data: this.priceList,
              type: 'line',
              scale: true,
              smooth: true
            }
          ]
        }

        myChart.setOption(option)
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      }
      this.$on('hook:destroyed', () => {
        window.removeEventListener('resize', function () {
          myChart.resize()
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
