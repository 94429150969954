<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-03-27 14:26:57
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-31 10:26:27
 * @FilePath: /siana_admin/src/views/index/components/header.vue
-->
<template>
  <el-card
    :body-style="{paddingLeft:'10px',paddintRight:'10px' }"
    style="margin-top: 20px;">
    <el-row :gutter="20">
      <el-col :lg="4" :md="4"
        class="col" :sm="12"
        :xs="12">
        <div class="item"
          :class="{'phone':phone?true:false}"
          style="background: linear-gradient(to right bottom, rgb(236, 71, 134), rgb(185, 85, 164));">
          <div class="titles">
            {{ objs.dayUserNumber }}
          </div>
          <div class="text">
            今日新增用户</div>
        </div>
      </el-col>
      <el-col class="col"
        :lg="4" :md="4"
        :sm="12" :xs="12">
        <div class="item"
          style="background: linear-gradient(to right bottom, rgb(134, 94, 192), rgb(81, 68, 180));"
          :class="{'phone':phone?true:false}">
          <div class="icon">
          </div>
          <div class="titles">
            {{ objs.dayOrderNumber }}
          </div>
          <div class="text">
            今日订单数</div>
        </div>
      </el-col>
      <el-col class="col"
        :lg="4" :md="4"
        :sm="12" :xs="12">
        <div class="item"
          style="background: linear-gradient(to right bottom, rgb(86, 205, 243), rgb(113, 157, 227));"
          :class="{'phone':phone?true:false}">
          <div class="icon">
          </div>
          <div class="titles">
            {{ objs.dayOkOrderNumber }}
          </div>
          <div class="text">
            今日有效订单数</div>
        </div>
      </el-col>
      <el-col class="col"
        :lg="4" :md="4"
        :sm="12" :xs="12">
        <div class="item"
          style="background: linear-gradient(to right bottom, rgb(252, 188, 37), rgb(246, 128, 87));"
          :class="{'phone':phone?true:false}">
          <div class="icon">
          </div>
          <div class="titles">
            {{ objs.dayPrice }}
          </div>
          <div class="text">
            今日收款金额</div>
        </div>
      </el-col>
      <!-- <el-col class="col"
        :lg="4" :md="4"
        :sm="12" :xs="12">
        <div class="item"
          style="background: linear-gradient(to right bottom, #fc5d25, #f62525);"
          :class="{'phone':phone?true:false}">
          <div class="icon">
          </div>
          <div class="titles">
            {{ objs.expirationNumber }}
          </div>
          <div class="text">
            今日到期用户数</div>
        </div>
      </el-col> -->
    </el-row>
  </el-card>
</template>

<script>
export default {
  props: ['obj', 'phone'],
  data() {
    return {
      objs: {
        expirationNumber: 0,
        dayPrice: 0,
        dayOkOrderNumber: 0,
        dayOrderNumber: 0,
        dayUserNumber: 0
      }
    }
  },
  watch: {
    obj: {
      handler(val) {
        this.objs = val
      }
    }
  },
  mounted() {
    this.objs = this.obj
  }
}
</script>

<style lang="scss" scoped>
.item.phone {
  margin-bottom: 20px;
}
.col:last-child .item.phone {
  margin-bottom: 0;
}
.item {
  border-radius: 10px;
  padding: 10px 0;
  .titles {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: #fff;
  }
  .text {
    font-size: 13px;
    color: #cccccc;
    text-align: center;
  }
}
</style>
